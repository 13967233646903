import {
    Box,
    Container,
    Flex,
    Image,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react';
  import Signup from '../components/forms/Signup';
  import PortfolioContest from '../assets/PortfolioContest.png';
  
  export default function Home() {
    return (
      <>
        <Box position={'relative'} bg ={'mkyfive.50'}>
          <Container maxW={'8xl'} zIndex={10} position={'relative'}>
            <Flex flexDirection={"column"} alignItems={"center"} w={"100%"}>
              <Box
                w={{ base: "40%%", md: "35%", lg: "35%" }} // Responsive width
                mx="auto"
              >
                <Image src={PortfolioContest} w={"100%"} pt={'15px'}/>
              </Box>
              {/* <Text color={'mkyone.main'}>
                We are looking for the next Warren Buffet! Contests will be starting 
                May 1, 2024. Sign up now to stay informed about the contests and 
                prizes and we will let you know when you can begin making your picks 
                for the contests.
              </Text> */}
              <Signup />
            </Flex>
          </Container>
        </Box>
      </>
    );
  }
  
  