    import {
      Box,
      chakra,
      Container,
      Link,
      Stack,
      Text,
      useColorModeValue,
      VisuallyHidden,
  } from '@chakra-ui/react';
  import {
      FaInstagram,
      FaTwitter,
      FaYoutube,
      FaFacebook,
      FaLinkedin,
      FaTiktok
  } from 'react-icons/fa';
  import { ReactNode } from 'react';
  import Logo from '../logo/logo'
  
  const packageJson = require('../../../package.json');
  
  const postUrl = 'https://www.marketocracy.io/';
  const postTitle = 'This may be of interest to you.'
  
  
  
  const SocialButton = ({ children, label, href }) => {
      return (
          <chakra.button
              bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
              rounded={'full'}
              w={8}
              h={8}
              cursor={'pointer'}
              as={'a'}
              href={href}
              display={'inline-flex'}
              alignItems={'center'}
              justifyContent={'center'}
              transition={'background 0.3s ease'}
              _hover={{
                  bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
              }}
          >
              <VisuallyHidden>{label}</VisuallyHidden>
              {children}
          </chakra.button>
      );
  };
  
  export default function SmallCentered() {
      return (
          <Box
              bg={useColorModeValue('gray.50', 'gray.900')}
              color={useColorModeValue('gray.700', 'gray.200')}
              marginTop={10}
          >
              <Container
                  as={Stack}
                  maxW={'6xl'}
                  py={2}
                  spacing={4}
                  justify={'center'}
                  align={'center'}
              >
                  <Stack direction={'row'} spacing={6}>
                      <Link href={'/'}>Home</Link>
                      <Link href={'/contact'}>Contact</Link>
                      <Link
                          href={
                              '#'
                          }
                          target="_blank"
                      >
                          Terms
                      </Link>
                      <Link
                          href={
                              '#'
                          }
                          target="_blank"
                      >
                          Privacy
                      </Link>
                  </Stack>
              </Container>
  
              <Box
                  borderTopWidth={1}
                  borderStyle={'solid'}
                  borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                  <Container
                      as={Stack}
                      maxW={'6xl'}
                      py={4}
                      direction={{ base: 'column', md: 'row' }}
                      spacing={4}
                      justify={{ base: 'center', md: 'space-between' }}
                      align={{ base: 'center', md: 'center' }}
                  >
                      <Text>
                          ©{new Date().getFullYear()}
                          {'  '} marketocracy.io All rights reserved
                          <small>
                              {'  '}v{packageJson.version}
                          </small>
                      </Text>
                      <Stack direction={'row'} spacing={6}>
                           <Text>Share Us: </Text>
                        <SocialButton
                            label={'Twitter'}
                            href={
                                ' https://twitter.com/share?url=https://www.marketocracy.io&text=Check this site out.&hashtags=MMCM, Marketocracy, Virtual Portfolio'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaTwitter color="#1da1f2" />
                        </SocialButton>
                        <SocialButton
                            label={'Facebook'}
                            colorScheme={'facebook'}
                            href={
                                'https://www.facebook.com/sharer.php?u=https://www.marketocracy.io'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaFacebook color="#1877f2" />
                        </SocialButton>
                        <SocialButton
                            label={'Linkedin'}
                            href={
                                'https://www.linkedin.com/shareArticle?url=https://www.marketocracy.io&title=This may be of interest to you.'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaLinkedin color="#0077b5" />
                        </SocialButton>
                      </Stack>
                  </Container>
              </Box>
          </Box>
      );
  }
  