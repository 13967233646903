import { React, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const Signup = () => {

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleButtonClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
  };

  const mailchimpFormHtml = `
<div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
  <style type="text/css">
    #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width: 100%; max-width: 600px; margin: 0 auto;}
  </style>
  <div id="mc_embed_signup">
    <form action="https://marketocracymasters.us21.list-manage.com/subscribe/post?u=c3224ad700171fede5495813f&amp;id=57d447ab5b&amp;f_id=00fdf7e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
      <div id="mc_embed_signup_scroll">
        <h2>Subscribe to Marketocracy's Newsletter</h2>
        <div class="indicates-required"><span class="asterisk"></span> indicates required</div>
        <!-- Form fields here -->
        <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk"></span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
        <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk"></span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div>
        <div class="mc-field-group"><label for="mce-LNAME">Last Name <span class="asterisk"></span></label><input type="text" name="LNAME" class="required text" id="mce-LNAME" required="" value=""></div>
        <div class="mc-field-group"><label for="mce-PHONE">Phone Number </label><input type="text" name="PHONE" class="REQ_CSS" id="mce-PHONE" value=""></div>
        <div class="mc-field-group input-group"><strong>Please select which you would like to receive: <span class="asterisk"></span></strong>
          <ul>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE60" value="Virtual Portfolio Contests" style="margin-right: 10px;"><label for="mce-MMERGE60">Virtual Portfolio Contests</label></li>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE61" value="Investing Newsletter" style="margin-right: 10px;"><label for="mce-MMERGE61">Investing Newsletter</label></li>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE62" value="Board Game Newsletter" style="margin-right: 10px;"><label for="mce-MMERGE62">Board Game Newsletter</label></li>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE63" value="All of the above" style="margin-right: 10px;"><label for="mce-MMERGE63">All of the above</label></li>
          </ul>
        </div>
        <div hidden=""><input type="hidden" name="tags" value="312761,466091"></div>
        <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style="display: none;"></div>
          <div class="response" id="mce-success-response" style="display: none;"></div>
        </div>
        <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_c3224ad700171fede5495813f_57d447ab5b" tabindex="-1" value=""></div>
        <div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
      </div>
    </form>
  </div>
  <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
  <script type="text/javascript">(function($) {
    window.fnames = new Array();
    window.ftypes = new Array();
    fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[4]='PHONE';ftypes[4]='phone';fnames[6]='MMERGE6';ftypes[6]='radio';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[7]='MMERGE7';ftypes[7]='text';fnames[8]='MMERGE8';ftypes[8]='text';fnames[3]='MMERGE3';ftypes[3]='text';
  }(jQuery));
  var $mcj = jQuery.noConflict(true);
  </script>
</div>
`;

  return (
    <Box>
      <Button
        bg="mkytwo.main"
        color="white"
        px="50px"
        py="15px"
        mt="2.5rem"
        borderRadius="10px"
        size="xl"
        fontWeight="bold"
        _hover={{ bg: 'mkytwo.400' }}
        onClick={handleButtonClick}
      >
        Sign up here!
      </Button>
      <Modal
        isOpen={isFormVisible}
        onClose={handleCloseForm}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent width="inherit">
          <ModalHeader>Sign Up Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              dangerouslySetInnerHTML={{ __html: mailchimpFormHtml }}
            ></div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Signup;