import React, { useEffect } from 'react';

const TradingViewWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    script.async = true;

    script.text = JSON.stringify({
      "feedMode": "all_symbols",
      "colorTheme": "light",
      "isTransparent": true,
      "displayMode": "regular",
      "width": "100%",
      "height": "300px",
      "locale": "en"
    });

    const container = document.querySelector('.stocks-2-widget-container__widget');

    if (container) {
      container.appendChild(script);
    }

    return () => {
      // Cleanup script when the component is unmounted
      if (container) {
        container.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
      <div className="stocks-2-widget-container">
        <div className="stocks-2-widget-container__widget"></div>
        <div className="stocks-2-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
            <span className="blue-text">Track all markets on TradingView</span>
          </a>
        </div>
      </div>
  );
};

export default TradingViewWidget;