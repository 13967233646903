import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Link,
  Image,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';

import { Routes, Route } from 'react-router-dom';

import myTheme from './theme';
import Footer from './components/footer/footer.jsx';
import Navbar from './components/navbar/navbar.jsx';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Board from './pages/gameboard';
import Portfolio from './pages/portfolio';

function App() {
  return (
    <ChakraProvider resetCSS theme={myTheme}>
      <Box textAlign="center" fontSize="xl">
        <Navbar />
        <Grid minH="100vh" p={3}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gameboard" element={<Board />}/>
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
        </Grid>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
