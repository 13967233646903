import axios from 'axios';

import {
  HOME_PAGE_CONTENT,
  ABOUT_US_CONTENT,
  INFO_CONTENT,
  TESTIMONIAL_CONTENT,
  EVENTS_CONTENT,
  SPONSORS_CONTENT,
  DONATE_CONTENT,
  SUMMARIES_CONTENT,
} from './queries';

export const getContactEndpoint = () => {
  let CONTACT_ENDPOINT =
    'https://mgcq49q48c.execute-api.us-east-2.amazonaws.com/beta/contact';

  if (process.env.REACT_APP_ENV_API_KEY === 'prod') {
    CONTACT_ENDPOINT =
      'https://km5vdfidod.execute-api.us-east-2.amazonaws.com/prod/contact';
  } else if (process.env.REACT_APP_ENV_API_KEY === 'beta') {
    CONTACT_ENDPOINT =
      'https://mgcq49q48c.execute-api.us-east-2.amazonaws.com/beta/contact';
  }

  return CONTACT_ENDPOINT;
};

const DFC_GQLEndpoint = 'https://d3ak2rni63wws7.cloudfront.net/cms/read/en-US';
const YT_Endpoint = 'https://d3ak2rni63wws7.cloudfront.net/cms/read/en-US';

const axiosDFC = axios.create({
  baseURL: DFC_GQLEndpoint,
  headers: {
    // Authorization: `bearer ${process.env.REACT_APP_GITHUB_PERSONAL_ACCESS_TOKEN}`,
    Authorization: `bearer a4f8eee9236560311dbd7153dc4eaf2b9c144d9141475e5b`,
  },
});

export const getHomePageContent = async name => {
  try {
    return await axiosDFC.post('', {
      query: HOME_PAGE_CONTENT,
      variables: { name },
    });
  } catch (e) {
    console.error('getHomePageContent Error: ', e);
  }
};

export const getAboutUs = async name => {
  try {
    return await axiosDFC.post('', {
      query: ABOUT_US_CONTENT,
      variables: { name },
    });
  } catch (e) {
    console.error('getAboutUs Error: ', e);
  }
};

export const getSummaries = async name => {
  try {
    return await axiosDFC.post('', {
      query: SUMMARIES_CONTENT,
      variables: { name },
    });
  } catch (e) {
    console.error('getAboutUs Error: ', e);
  }
};

export const getInfoCards = async pageId => {
  try {
    return await axiosDFC.post('', {
      query: INFO_CONTENT,
      variables: { pageId },
    });
  } catch (e) {
    console.error('getInfoCards Error: ', e);
  }
};
export const getTestimonials = async () => {
  try {
    return await axiosDFC.post('', {
      query: TESTIMONIAL_CONTENT,
    });
  } catch (e) {
    console.error('getTestimonials Error: ', e);
  }
};
export const getEvents = async () => {
  try {
    return await axiosDFC.post('', {
      query: EVENTS_CONTENT,
    });
  } catch (e) {
    console.error('getEvents Error: ', e);
  }
};
export const getSponsors = async () => {
  try {
    return await axiosDFC.post('', {
      query: SPONSORS_CONTENT,
    });
  } catch (e) {
    console.error('getSponsors Error: ', e);
  }
};

export const getLatestYTVideo = async () => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const channelId = process.env.REACT_APP_CHANNEL_ID;
    const url = process.env.REACT_APP_YT_URL;
    return await axios.get(`${url}search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=1`);
  } catch (e) {
    console.error('getLatestYTVideo Error: ', e);
  }
}
