import React, { useEffect } from 'react';

const FinlogixWidget = () => {
  useEffect(() => {
    // Initialize the Finlogix widget when the component mounts
    const script = document.createElement('script');
    script.src = 'https://widget.finlogix.com/Widget.js';
    script.async = true;

    script.onload = () => {
      // Widget initialization code
      window.Widget.init({
        widgetId: "a24f576d-1f59-426b-a250-acec7469bf31",
        type: "MarketChart",
        language: "en",
        ranges: [
          "1D",
          "1M",
          "3M",
          "1Y",
          "5Y",
          "ALL"
        ],
        custom: {
          subclasses: [
            {
                    subtitle: "Forex",
                    symbolPairs: []
            },
            {
                    subtitle: "Indices",
                    symbolPairs: [
                            {
                                    symbolId: "52",
                                    symbolName: "SP500"
                            },
                            {
                                    symbolId: "51",
                                    symbolName: "DJ30"
                            },
                            {
                                    symbolId: "50",
                                    symbolName: "NAS100"
                            }
                    ]
            },
            {
                    subtitle: "Stocks",
                    symbolPairs: [
                            {
                                    symbolId: "10006",
                                    symbolName: "Amazon"
                            },
                            {
                                    symbolId: "10007",
                                    symbolName: "Apple"
                            },
                            {
                                    symbolId: "10014",
                                    symbolName: "Tesla"
                            },
                            {
                                    symbolId: "10021",
                                    symbolName: "NVIDIA"
                            },
                            {
                                    symbolId: "10028",
                                    symbolName: "MetaPlatformsInc"
                            },
                            {
                                    symbolId: "10004",
                                    symbolName: "Alphabet"
                            },
                            {
                                    symbolId: "10029",
                                    symbolName: "Microsoft"
                            }
                    ]
            },
            {
                    subtitle: "Commodities",
                    symbolPairs: [
                            {
                                    symbolId: "44",
                                    symbolName: "XAU/USD"
                            },
                            {
                                    symbolId: "43",
                                    symbolName: "XAG/USD"
                            },
                            {
                                    symbolId: "45",
                                    symbolName: "WTI"
                            },
                            {
                                    symbolId: "70",
                                    symbolName: "BRENT"
                            },
                            {
                                    symbolId: "113",
                                    symbolName: "COPPER"
                            }
                    ]
            },
            {
                    subtitle: "Cryptocurrency",
                    symbolPairs: [
                            {
                                    symbolId: "66",
                                    symbolName: "BTC/USD"
                            },
                            {
                                    symbolId: "67",
                                    symbolName: "ETH/USD"
                            }
                    ]
            },
            {
                    subtitle: "ETFs",
                    symbolPairs: []
            },
            {
                    subtitle: "Futures",
                    symbolPairs: []
            }
    ]
},
        isAdaptive: true
      });
    };

    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="finlogix-container"></div>;
};

export default FinlogixWidget;
