import { React, useState } from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  List,
  ListItem,
  Button,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import boxtop1 from '../assets/boxtop1.png';
import boxtop2 from '../assets/boxtop2.png';

const mailchimpFormHtml = `
<div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
  <style type="text/css">
    #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width: 100%; max-width: 600px; margin: 0 auto;}
  </style>
  <div id="mc_embed_signup">
    <form action="https://marketocracymasters.us21.list-manage.com/subscribe/post?u=c3224ad700171fede5495813f&amp;id=57d447ab5b&amp;f_id=00fdf7e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
      <div id="mc_embed_signup_scroll">
        <h2>Subscribe to Marketocracy's Newsletter</h2>
        <div class="indicates-required"><span class="asterisk"></span> indicates required</div>
        <!-- Form fields here -->
        <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk"></span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
        <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk"></span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div>
        <div class="mc-field-group"><label for="mce-LNAME">Last Name <span class="asterisk"></span></label><input type="text" name="LNAME" class="required text" id="mce-LNAME" required="" value=""></div>
        <div class="mc-field-group"><label for="mce-PHONE">Phone Number </label><input type="text" name="PHONE" class="REQ_CSS" id="mce-PHONE" value=""></div>
        <div class="mc-field-group input-group"><strong>Please select which you would like to receive: <span class="asterisk"></span></strong>
          <ul>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE60" value="Virtual Portfolio Contests" style="margin-right: 10px;"><label for="mce-MMERGE60">Virtual Portfolio Contests</label></li>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE61" value="Investing Newsletter" style="margin-right: 10px;"><label for="mce-MMERGE61">Investing Newsletter</label></li>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE62" value="Board Game Newsletter" style="margin-right: 10px;"><label for="mce-MMERGE62">Board Game Newsletter</label></li>
            <li><input type="radio" name="MMERGE6" id="mce-MMERGE63" value="All of the above" style="margin-right: 10px;"><label for="mce-MMERGE63">All of the above</label></li>
          </ul>
        </div>
        <div hidden=""><input type="hidden" name="tags" value="312761,466091"></div>
        <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style="display: none;"></div>
          <div class="response" id="mce-success-response" style="display: none;"></div>
        </div>
        <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_c3224ad700171fede5495813f_57d447ab5b" tabindex="-1" value=""></div>
        <div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
      </div>
    </form>
  </div>
  <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
  <script type="text/javascript">(function($) {
    window.fnames = new Array();
    window.ftypes = new Array();
    fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[4]='PHONE';ftypes[4]='phone';fnames[6]='MMERGE6';ftypes[6]='radio';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[7]='MMERGE7';ftypes[7]='text';fnames[8]='MMERGE8';ftypes[8]='text';fnames[3]='MMERGE3';ftypes[3]='text';
  }(jQuery));
  var $mcj = jQuery.noConflict(true);
  </script>
</div>
`;

export default function Board() {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleButtonClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
  };

  return (
    <>
      <Box position={'relative'}>
        <Container maxW={'8xl'} zIndex={10} position={'relative'}>
          <Stack
            flex={1}
            color={'mkyfive.50'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 10, xl: '1em' }}
          >
            <Box>
              <Stack spacing={{ base: '4', md: '5' }} align="left">
                <Text as="h3" color="mkytwo.main" fontSize="5xl">
                  The Marketocracy Board Game is coming soon!
                </Text>
                <Text as="h3" color="mkyone.main" fontSize="2xl" mb="30px">
                  The perfect gift to help everyone learn about Personal Finance
                  &amp; Investing!
                </Text>
                <Flex
                  direction={{ base: 'column', sm: 'row' }}
                  mt="0"
                  justifyContent="center"
                >
                  <Box
                    mr={{ base: '0px', sm: '10px' }}
                    mb={{ base: '10px', sm: '0px' }}
                  >
                    <Image
                      src={boxtop1}
                      alt="marketocracy board game box top image 1"
                      w="100%"
                    />
                  </Box>
                  <Box
                    ml={{ base: '0px', sm: '10px' }}
                    mt={{ base: '10px', sm: '0px' }}
                  >
                    <Image
                      src={boxtop2}
                      alt="marketocracy board game box top image 2"
                      w="100%"
                    />
                  </Box>
                </Flex>
                <Box mt="20px">
                  <Text as="h3" color="mkyone.main" fontSize="3xl" mb="30px">
                    Sign Up today to{' '}
                    <Text as="span" color="red.500">
                      receive a $10 discount{' '}
                    </Text>
                    and notification of when you can purchase the game. We
                    expect to launch the game on Kickstarter sometime in 2025
                    with a price of $39.99, so if you sign up you will be able
                    to get the game for $29.99 for a limited time.
                  </Text>
                </Box>
                <Box>
                  <Text
                    color="mkyone.main"
                    fontWeight="bold"
                    textAlign="left"
                    fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}
                    // p="10px"
                    m="0px"
                  >
                    The game is designed to be FUN first, but also educational.
                    By playing this game you can learn about Personal Finance
                    including:
                  </Text>
                  <List
                    spacing="3"
                    fontSize={{ base: 'lg', sm: 'xl' }}
                    p={{ base: '10px', xl: '20px' }}
                  >
                    <Box m="0px">
                      <ListItem
                        pt="15px"
                        pl="20px"
                        color="mkyone.main"
                        fontWeight="medium"
                        textAlign="left"
                      >
                        &#8226; How the Macro Economy as reported through
                        economic reports affects the stock market and all
                        investments.
                      </ListItem>
                    </Box>
                    <Box m="0px">
                      <ListItem
                        pl="20px"
                        color="mkyone.main"
                        fontWeight="medium"
                        textAlign="left"
                      >
                        &#8226; What a Black Swan is and how it affects your
                        portfolio.
                      </ListItem>
                    </Box>
                    <Box m="0px">
                      <ListItem
                        pl="20px"
                        color="mkyone.main"
                        fontWeight="medium"
                        textAlign="left"
                      >
                        &#8226; How Microeconomics are reported through Earnings
                        Reports and how that affects individual investments that
                        you hold.
                      </ListItem>
                    </Box>
                    <Box m="0px">
                      <ListItem
                        pl="20px"
                        color="mkyone.main"
                        fontWeight="medium"
                        textAlign="left"
                      >
                        &#8226; The behavioral influences that drive momentum in
                        the market and understanding when to prosper from it or
                        avoid it.
                      </ListItem>
                    </Box>
                    <Box m="0px">
                      <ListItem
                        pl="20px"
                        color="mkyone.main"
                        fontWeight="medium"
                        textAlign="left"
                      >
                        &#8226; Unexpected things happen in life and how they
                        can change your investing outlook.
                      </ListItem>
                    </Box>
                  </List>
                </Box>
              </Stack>
              <Button
                bg="mkytwo.main"
                color="white"
                px="50px"
                py="15px"
                mt="2.5rem"
                borderRadius="10px"
                size="xl"
                fontWeight="bold"
                _hover={{ bg: 'mkytwo.400' }}
                onClick={handleButtonClick}
              >
                Sign up here!
              </Button>
              <Modal
                isOpen={isFormVisible}
                onClose={handleCloseForm}
                size="2xl"
              >
                <ModalOverlay />
                <ModalContent width="inherit">
                  <ModalHeader>Sign Up Form</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <div
                      dangerouslySetInnerHTML={{ __html: mailchimpFormHtml }}
                    ></div>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
