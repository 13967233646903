import {
  Box,
  Button,
  Textarea,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Alert,
  AlertIcon,
  FormHelperText,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useState} from 'react'
import axios from "axios";


const submitForm = () => {
const headers = {
  headers: {
      "Content-Type": "application/json",
  },
};

const handleReset = () => {
  document.getElementById("name").value = ""
  document.getElementById("email").value = ""
  document.getElementById("message").value = ""

};

axios.post(
      "https://mgcq49q48c.execute-api.us-east-2.amazonaws.com/beta/contact",
      {
          Name: document.getElementById("name").value,
          Email: document.getElementById("email").value,
          Message: document.getElementById("message").value,
          Service: "Marketocracy",
      },
      headers
  )
  .then((res) => {
      document.getElementById("success_alert").style.display = "block";
      document.getElementById("error_alert").style.display = "none";
      handleReset()
  })
  .catch((err) => {
      console.error(err);
      document.getElementById("success_alert").style.display = "none";
      document.getElementById("error_alert").style.display = "block";
  });
};

export default function Contact() {
  //Setting State
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  //Disable button for empty fields
  const isInvalid = name === '' || email === '' || message === ''

  //Function calls for empty fields as a precaution
  const handleNameChange = (e) => setName(e.target.value)
  const isNameError = name === ''

  const handleEmailChange = (e) => setEmail(e.target.value)
  const isEmailError = email === ''

  const handleMessageChange = (e) => setMessage(e.target.value)
  const isMessageError = message === ''


  return (
      <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
          <Stack spacing="8">
              <Stack spacing="6">
                  <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                      <Heading 
                          color={'mkytwo.500'}
                          mb={2}
                          fontSize={{ base: '3xl', md: '3xl' }}
                          textAlign={'center'}
                      >
                      Contact Us
                      </Heading>
                  </Stack>
              </Stack>
              <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={'mkyfive.50'}
              boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
              borderRadius={{ base: 'none', sm: 'xl' }}
              >
                  <Stack spacing="6">
                      <Stack spacing="6">
                          <FormControl isRequired>
                              <FormLabel 
                                  htmlFor="name" 
                                  color={'mkytwo.500'}
                              >
                                  Name
                              </FormLabel>
                                  <Input 
                                      isInvalid={isNameError}
                                      id="name" 
                                      type="text" 
                                      borderColor='mkyftwo.500' 
                                      onChange={handleNameChange}
                                  />
                                  {!isNameError ? (
                                          <FormHelperText 
                                              color={'mkytwo.500'} 
                                              textAlign={'left'}
                                          >
                                          Enter your full name.
                                          </FormHelperText>
                                          ) : (
                                          <FormErrorMessage 
                                              color='red'
                                          >
                                              Name is required.
                                          </FormErrorMessage>
                                        )               
                                  }
                              <FormLabel 
                                  htmlFor="email" 
                                  color={'mkytwo.500'} 
                                  mt={'4'}
                              >
                                  Email
                              </FormLabel>
                                  <Input 
                                      isInvalid={isEmailError}
                                      id="email" 
                                      type="email" 
                                      borderColor='mkyftwo.500'
                                      onChange={handleEmailChange} 
                                  />
                                  {!isEmailError ? (
                                          <FormHelperText 
                                              color={'mkytwo.500'} 
                                              textAlign={'left'}
                                          >
                                          Enter the email you'd like to receive the reply to.
                                          </FormHelperText>
                                          ) : (
                                          <FormErrorMessage 
                                              color={'red'}
                                          >
                                              Email is required.
                                          </FormErrorMessage>
                                        )               
                                  }
                              <FormLabel 
                                  htmlFor="Message" 
                                  color={'mkytwo.500'} 
                                  mt={'4'}
                              >
                                  Message
                              </FormLabel>
                              <Textarea 
                                  isInvalid={isMessageError}
                                  id="message"
                                  borderColor='mkyftwo.500'
                                  onChange={handleMessageChange} 
                              />
                              {!isMessageError ? (
                                      <FormHelperText 
                                          color={'mkytwo.500'} 
                                          textAlign={'left'}
                                      >
                                      Enter the message you wish to send to Marketocracy Masters Capital Management.
                                      </FormHelperText>
                              ) : (
                                      <FormErrorMessage 
                                          color={'red'}
                                      >
                                          Message is required to proceed.
                                      </FormErrorMessage>
                              )               
                              }
                          </FormControl>
                      </Stack>
                      <Stack spacing="2">
                              <Button
                                  rounded={'full'}                  
                                  fontWeight={'normal'}
                                  bg="mkytwo.500"
                                  color='mkyfive.50'
                                  _hover={{ 
                                      bg: 'mkyfive.50', 
                                      color: 'mkytwo.500', 
                                      border: '2px', 
                                      borderColor: 'mkyftwo.500'
                                  }}
                                  onSubmit
                                  px={6}
                                  mt={5}
                                  onClick={() => submitForm()}
                                  disabled={isInvalid}
                              >
                                  Submit
                              </Button>
                              <Text 
                                  fontSize='xs'
                                  color='red'
                                  mt='-3'
                              >
                                  * You must fill in all information to submit!
                              </Text>
                              <Alert 
                                  status='error' 
                                  variant='top-accent' 
                                  id='error_alert' 
                                  style={{ display: "none"}}
                              >
                                  <AlertIcon />
                                  There was an error processing your request!
                              </Alert>
                              <Alert 
                                  status='success' 
                                  variant='top-accent' 
                                  id='success_alert' 
                                  style={{ display: "none"}}
                              >
                                  <AlertIcon />
                                  Your message was sent successfully!
                              </Alert>
                          <HStack>
                          <hr color={'mkytwo.500'} />
                              <Text fontSize="lg" 
                              whiteSpace="nowrap" 
                              color="mkytwo.500" 
                              textAlign={'left'} 
                              pt='8'>
                                  Marketocracy.io<br />
                                  20401 Haggerty Rd. STE A 131<br />
                                  Northville, MI 48167-1999<br />
                                  {/* 1-xxx-xxx-xxxx */}
                              </Text>
                          </HStack>
                      </Stack>
                  </Stack>
              </Box>
          </Stack>
      </Container>
)
}



