import {
  Box,
  Tooltip,
  Button,
  Stack,
  Text,
  Heading,
  Container,
  Link,
  Image,
  Divider,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import Youtube from '../components/socialmedia/Youtube';
import React from 'react';
import Signup from '../components/forms/Signup';
import Links from '../components/socialmedia/Links';
import Articles from '../components/socialmedia/NewsLetters';
import TradingViewWidget from '../components/widgets/TradingViewWidget';
import FinlogixWidget from '../components/stocks/Finlogix';

export default function Home() {
  return (
    <>
      <Box position={'relative'}>
        <Stack
          flex={1}
          color={'mkyfive.50'}
          justify={{ lg: 'center' }}
          py={{ base: 4, md: 10, xl: '1em' }}
        >
          <Box mb={{ base: 8, md: 8 }}>
            <Container
              py={3}
              maxW={'8xl'}
              zIndex={10}
              position={'relative'}
              bg={'mkytwo.500'}
              borderRadius={'xl'}
              boxShadow="dark-lg"
              alignContent={'center'}
            >
              <Box
                h={{ base: '3220px', sm: '3220px', md: '1300px', lg: '1300px' }}
                gap={1}
                display={"flex"}
                w={"100%"}
                flexDirection={{ base: "column", sm: "column", md: "row", lg: "row" }}
                mt={{ base: "2%", sm: "2%", md: "0", lg: "0" }}
              >
                <Box
                  display={"flex"}
                  w={{ base: "100%", sm: "100%", md: "62%", lg: "62%" }}
                  flexDirection={"column"}
                  justifyContent={{ base: "space-between" }}
                  h={{ base: "60%", sm: "60%", md: "100%", lg: "100%" }}
                >
                  <Box
                    display={"flex"}
                    w={"100%"}
                    mb={'7px'}
                    flexDirection={"column"}
                    justifyContent={{ base: "space-between" }}
                    h={{ base: "8%", sm: "8%", md: "30%", lg: "30%" }}
                    boxShadow="0px 0px 10px 0px rgba(112, 172, 70, 0.8)"// Set the drop shadow color
                  >
                    <Box
                      h={{ base: "100%", sm: "100%", md: "96.5%", lg: "96.5%" }}
                      w={"100%"}

                      bg={'white'}
                      borderRadius={'xl'}
                      color={'mkyone.900'}
                      alignContents={'center'}
                    >
                      <Link
                        href={'/portfolio'}
                      >
                        <Image
                          pt={'15px'}
                          borderRadius={'xl'}
                          width={'100%'}
                          alignContents={'center'}
                          src='https://marketocracy-io.s3.us-east-2.amazonaws.com/warren-buffet.png' alt='Warren Buffet tile' />
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    w={"100%"}
                    flexDirection={"column"}
                    justifyContent={{ base: "space-between" }}
                    h={{ base: "30%", sm: "30%", md: "30%", lg: "30%" }}
                    mt={{ base: "2%", sm: "2%", md: "0", lg: "0" }}
                  >
                    <Box
                      h={{ base: "100%", sm: "100%", md: "96.5%", lg: "96.5%" }}
                      w={"100%"}
                      bg={'#70AC46'}
                      borderRadius={'xl'}
                      color={'mkyone.900'}
                      boxShadow='0 0 35px rgba(112, 172, 70, 0.8)'
                      border='2px'
                      borderColor='mkyone.600'
                      padding={"20px"}
                    >
                      <Heading>
                        Sign up for our Free Newsletter
                      </Heading>
                      <Text
                        marginTop={"20px"}
                        ml={'20%'}
                        mr={'20%'}
                      >
                        to get our take on the latest market moves, best ideas, and articles

                        to build your financial literacy.</Text>
                      <Signup />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    w={"100%"}
                    flexDirection={{ base: "column", sm: "column", md: "row", lg: "row" }}
                    justifyContent={{ base: "space-between" }}
                    height={"100%"}
                  >
                    <Box
                      mt={{ base: "2%", sm: "2%", md: "0", lg: "0" }}
                      w={{ base: "100%", sm: "100%", md: "49%", lg: "49%" }}
                      bg={'white'}
                      borderRadius={'xl'}
                      color={'mkyone.900'}
                      height={{ base: "49%", sm: "49%", md: "100%", lg: "100%" }}
                    >
                      <Box
                        width={"100%"}
                        textAlign={"left"}
                        pl={"10px"}
                      >
                      </Box>
                      <Box
                        width={"100%"}
                        height={"750px"}
                        overflowY={"auto"}
                        marginTop={"10px"}
                      >
                        <TradingViewWidget />
                      </Box>
                      <Box
                        width={"100%"}
                        marginTop={"10px"}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                      >
                        <Links />
                      </Box>
                    </Box>
                    <Box
                      w={{ base: "100%", sm: "100%", md: "49%", lg: "49%" }}
                      bg={'black'}
                      borderRadius={'xl'}
                      color={'mkyone.900'}
                      height={{ base: "49.8%", sm: "49.5%", md: "100%", lg: "100%" }}
                      mt={{ base: "0", sm: "0", md: "0", lg: "0" }}
                      pb={0}
                    >
                      <Box
                        w={"100%"}
                        borderTopRadius={'xl'}
                        color={'mkyfive.50'}
                        height={"33.333333%"}
                        textAlign={"left"}
                        paddingLeft={"10px"}
                        paddingRight={"10px"}
                        overflowY={"auto"}
                        mt={'3'}
                        mb={'3'}
                      >
                        <Heading
                          fontSize={"xl"}
                          color={'mkyfive.50'}
                          mb={'3'}
                        >
                          Our Latest Podcasts
                        </Heading>
                        <iframe
                          title="Ring the Bell with the Masters of Marketocracy"
                          allowtransparency="true"
                          height="315"
                          width="100%"
                          data-name="pb-iframe-player"
                          src="https://www.podbean.com/player-v2/?i=qvjc3-f4739d-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=1b1b1b&font-color=auto&logo_link=episode_page&order=episodic&limit=5&filter=all&ss=bcd3b6878f94d235528ab1ba3e3d596d&btn-skin=3267a3&size=315"
                          loading="lazy"
                          allowfullscreen=""></iframe>
                      </Box>
                      <Box
                        w={"100%"}
                        color={'mkyfive.50'}
                        height={"33.333333%"}
                        textAlign={"left"}
                        paddingLeft={"10px"}
                        mt={'3'}
                        mb={'3'}
                      >
                        <Divider color="MKYthree.500" size="xl"/>
                        <Heading
                          fontSize={"xl"}
                          color={'mkyfive.50'}
                          mb={'3'}
                        >
                          Our Latest Videos
                        </Heading>
                        <Youtube
                        />
                      </Box>
                      <Box
                        w={"100%"}
                        borderBottomRadius={'xl'}
                        color={'mkyfive.50'}
                        textAlign={"left"}
                        paddingLeft={"10px"}
                        paddingRight={"10px"}
                        mt={'-4'}
                      >
                        <Divider borderColor="MKYthree.500" size="10px"/>
                        <Heading
                          fontSize={"xl"}
                          color={'mkyfive.50'}
                          mb={'3'}
                        >
                          Our Latest Newsletters
                        </Heading>
                        <Articles />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  h={{ base: "50%", sm: "50%", md: "100%", lg: "100%" }}
                  w={{ base: "100%", sm: "100%", md: "38%", lg: "38%" }}
                  flexDirection={"column"}
                  alignItems={"end"}
                  marginTop={{ base: "6%", sm: "6%", md: "0", lg: "0" }}
                >
                  <Box
                    display={"flex"}
                    h={{ base: "52%", sm: "52%", md: "52.5%", lg: "52.5%" }}
                    w={{ base: "100%", sm: "100%", md: "98%", lg: "98%" }}
                    flexDirection={{ base: "column", sm: "column", md: "row", lg: "row" }}
                    borderLeftRadius={"xl"}
                    borderRadius={"xl"}
                    color={'mkyone.900'}
                    bg={"white"}
                    mt={{ base: "160px", sm: "160px", md: "0", lg: "0" }}
                  >
                    <FinlogixWidget />
                  </Box>
                  <Box
                    mt={"2.5%"}
                    bg={'white'}
                    borderRadius={'xl'}
                    color={'mkyone.900'}
                    height={"35%"}
                    alignContents={'center'}
                    width={{ base: "100%", sm: "100%", md: "98%", lg: "98%" }}
                  >
                    <Image
                      borderRadius={'xl'}
                      width={'100%'}
                      src='https://marketocracy-io.s3.us-east-2.amazonaws.com/indicators/Marketocracy+Indicator-5.png' alt='Market Indicator' />
                      <Tooltip 
                        hasArrow 
                        label='This market indicator is a reflection of the buying sentiment of our investors and is not intended as an indicator of what any one investor should be doing in the market as all investment decisions vary based on individual situations.' 
                        bg='red.600'
                      >
                        <Button 
                          bg="white"
                          color="mkyone.900"
                          _hover={{ 
                            bg: "white", 
                            color: "red" 
                          }}
                        >
                          Disclaimer
                        </Button>
                      </Tooltip>
                  </Box>
                  <Box
                    borderStartRadius={"xl"}          
                    marginTop={"6%"}
                    mt={{ base: "3%", sm: "3%", md: "6%", lg: "6%" }}
                    h={{ base: "20%", sm: "20%", md: "35%", lg: "35%" }}
                    w={{ base: "100%", sm: "100%", md: "98%", lg: "98%" }}
                    bg={'mkyfive.50'}
                    borderRadius={'xl'}
                    color={'mkyone.900'}
                    overflowY={"auto"}
                  >
                    <iframe
                      src="https://fred.stlouisfed.org/fred-glance-widget.php?series_ids=DGS10,DGS2,CPIAUCSL,INDPRO,PAYEMS,UNRATE,GDPC1&transformations=lin,lin,pc1,pch,chg,lin,pca"
                      height="100%"
                      width="100%"
                      frameborder="0"
                      style={{
                        backgroundColor: "transparent"
                      }}
                    ></iframe>
                  </Box>
                </Box>

              </Box>
            </Container>
          </Box>
        </Stack>
      </Box>
    </>
  );
}