import React from 'react'
import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaLinkedin
} from 'react-icons/fa';
const Links = () => {

  const SocialButton = ({ children, label, href }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };

  return (
    <Stack direction={'row'} spacing={6}>
      <SocialButton
        label={'Twitter'}
        href={' https://twitter.com/Marketocracy2'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter color="#1da1f2" />
      </SocialButton>
      <SocialButton
        label={'Facebook'}
        colorScheme={'facebook'}
        href={'https://www.facebook.com/profile.php?id=100087197932735'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook color="#1877f2" />
      </SocialButton>
      <SocialButton
        label={'YouTube'}
        href={'https://www.youtube.com/channel/UCX2C5qOnCazAuCrGJ0asx0g'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaYoutube color="#c4302b" />
      </SocialButton>
      <SocialButton
        label={'Instagram'}
        href={'https://www.instagram.com/marketocracy/'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram color="#bc2a8d" />
      </SocialButton>
      <SocialButton
        label={'Linkedin'}
        href={
          'https://www.linkedin.com/company/marketocracymasterscm/'
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin color="#0077b5" />
      </SocialButton>
      {/* <SocialButton
        label={'TikTok'}
        href={
            'https://www.linkedin.com/company/marketocracymasterscm/'
        }
        target="_blank"
        rel="noopener noreferrer"
    >
        <FaTiktok color="#0077b5"/>
    </SocialButton> */}
    </Stack>
  )
}

export default Links