import React from 'react'
import { Skeleton } from '@chakra-ui/react'
const PlainSkeleton = ({
  startColor = 'gray.500',
  endColor = 'gray.700',
  children,
  rest
}) => {
  return (
    <Skeleton
      startColor={startColor}
      endColor={endColor}
      width={"100%"}
      height={"225"}
      {...rest}
    >
      {children}
    </Skeleton>
  )
}

export default PlainSkeleton