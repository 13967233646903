import { React } from 'react';
import {
    Box,
    Flex,
    Container,
    Stack,
    Heading,
    Text,
    Link
} from '@chakra-ui/react';
import Signup from '../components/forms/Signup';
import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function About() {
    // let navigate = useRedirect();
    return (
        <>
            <Box position={'relative'}>
                <Container maxW={'8xl'} zIndex={10} position={'relative'}>
                    <Stack
                        flex={1}
                        color={'mkyfive.50'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 10, xl: '1em' }}
                    >
                        <Box mb={{ base: 8, md: 8 }}>
                            <Heading
                                color={'mkytwo.500'}
                                mb={2}
                                fontSize={{ base: '3xl', md: '3xl' }}
                                textAlign={'left'}
                            >
                                About Us
                            </Heading>
                            <Container
                                py={3}
                                maxW={'8xl'}
                                zIndex={10}
                                position={'relative'}
                                bg={'mkytwo.500'}
                                borderRadius={'xl'}
                                boxShadow="dark-lg"
                            >
                                <Text
                                    fontSize={'lg'}
                                    color={'mkyfive.50'}
                                    textAlign={'left'}
                                    px={'.75em'}
                                >
                                     Marketocracy provides financial and educational 
                                     information and tools to help individual investors make 
                                     smarter investment decisions with a goal of building 
                                     knowledge and wealth.  Our site also provides commentary 
                                     and opinions from Marketocracy’s Masters – select investors 
                                     with proven track records of outstanding performance. 
                                    <br />
                                    <br />
                                    The investment information provided on Marketocracy.io is 
                                    designed for a general audience and might not be suitable or 
                                    appropriate for all investors.  Individuals should always 
                                    perform their own due diligence as well as consider goals 
                                    and personal risk tolerances before investing. In some cases, 
                                    it is advisable to consult with a financial professional. 
                                    Marketocracy is a division of Marketocracy Masters Inc., 
                                    a Delaware Corporation.  
                                    <br />
                                    <br />
                                    The model portfolios of the Marketocracy Masters can be followed at 
                                    <Link 
                                        color="mkythree.300" 
                                        href="https://www.marketocracymasters.com/" 
                                        target="_blank" rel="noopener noreferrer"
                                        textDecoration="underline">
                                           {' '}MarketocracyMasters.com.
                                    </Link>
                                    MarketocracyMasters.com is the website of Marketocracy Masters Capital Management, a SEC Registered Investment Advisor. Marketocracy Masters Capital Management was formed by a group of portfolio managers who have proven long-term track records of superior performance. 
                                    <br />
                                    <br />
                                    Marketocracy Masters were first identified by Ken Kam, co-founder and portfolio manager of First Hand Funds, who created a revolutionary investor website in 2000 called Marketocracy.com with a mission to democratize investing. Marketocracy’s guiding philosophy is that there are people outside of Wall Street with specialized areas of expertise that are actually excellent, but unknown, stock analysts. Using a rigorous data driven methodology, Kam’s website tracked the portfolios of tens of thousands of individual investors over many years in order to determine which investors had superior long-term track records, and then went on to analyze the top performers underlying investment strategies.  Some of Marketocracy’s best performing individual investors were feature in The Warren Buffett’s Next Door: The World’s Greatest Investors You’ve Never Heard Of and What You Can Learn From Them (Wiley:2011).
                                    <br />
                                    <br />
                                    The founders of Marketocracy Masters Capital Management are among the investors that Kam recognized as “Masters” because of their long-term performance managing stock portfolios. In most cases their investment returns far exceeded the best mutual funds managed by professional Wall Street money managers.
                                    <br />
                                    <br />
                                    These Masters, have re-launched Marketocracy.com to honor Ken Kam’s legacy and continue his mission to find and cultivate future generations of outstanding investors. 
                                    <br />
                                    <br />
                                    <Link 
                                        color="mkythree.300" 
                                        href="/portfolio" 
                                        textDecoration="underline">
                                        {' '}Click here
                                    </Link> to join the challenge to see how you compare to some of the best investors on and off Wall Street!
                                    <Link 
                                        color="mkythree.300" 
                                        href="/portfolio" 
                                        textDecoration="underline">
                                           {' '}Join a virtual portfolio contest.
                                    </Link>
                                    <br />
                                    <br />
                                    {/* <Link 
                                        color="mkythree.300" 
                                        href="/portfolio" 
                                        textDecoration="underline">
                                           {' '}Click here
                                    </Link> to join the challenge to see how you compare to some of the best investors on and off Wall Street! */}
                                   {/* Click here to join the challenge to see how you compare to some of the best investors on and off Wall Street! */}
                                    Invest with a Master at 
                                    <Link 
                                        color="mkythree.300" 
                                        href="https://www.marketocracymasters.com/" 
                                        target="_blank" rel="noopener noreferrer"
                                        textDecoration="underline">
                                           {' '}MarketocracyMasters.com.
                                    </Link>
                                    <br />
                                    <br />
                                    Sign up to receive our free Weekly Newsletter, Marketocracy Intelligence. 
                                    <Box alignItems="center"> 
                                        <Signup />
                                    </Box>
                                    <br />
                                </Text>
                            </Container>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}