import React, { useState, useEffect } from 'react';
import {
  Box,
  Text
} from '@chakra-ui/react';
import PlainSkeleton from '../skeletons/PlainSkeleton';
import { getLatestYTVideo } from '../../api';

const Youtube = () => {
  const [videoId, setVideoId] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getLatestYTVideo()
      .then(response => {

        if (response.data.hasOwnProperty("items") && response.data.items.length > 0) {
          const videoId = response.data.items[0].id.videoId;
          setVideoId(videoId);
        } else {
          console.log(response);
        }
        setIsLoaded(true);
      })
      .catch(error => {
        console.error('Error fetching video data:', error);
        setIsLoaded(true);
      });
  }, []);

  return (
    <Box w={"100%"} pr={"10px"} mt={"10px"} className="video-container">
      {isLoaded ?
        videoId ?
          <iframe
            title="Latest Video"
            width="100%"
            height="225"
            src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
          ></iframe>
          :
          <Box
            w={"100%"}
            height={"225"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"start"}
          >
            <Text>Video not found</Text>
          </Box>
        :
        <PlainSkeleton></PlainSkeleton>
      }
    </Box>
  );
};

export default Youtube;