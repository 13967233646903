import { Box } from '@chakra-ui/layout';
import React, { useEffect } from 'react';

const NewsLetters = () => {
  return (
    <Box
      maxH={"100%"}
      overflowY={"auto"}
      backgroundColor={"white"}
      h={"182px"}
    >
      <iframe
        title="Mailchimp Integration"
        src="https://superior-programmers.github.io/mailchimp-newsletters/"
        width="100%"

      />

    </Box>
  );
};

export default NewsLetters;